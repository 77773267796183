import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import NewsPageLayout from '../components/NewsPageLayout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'

/**
 * News Archive Template
 * 
 * @param {object} data
 * @param {object} pageContext
 *
 * @returns {JSX Element}
 */
export default function newsArchiveTemplate({ data, pageContext }) {
  //Get array of components
  const { allWpPost,
    wpPage: { title, translations, language },
    allWpMenu, allWp: { nodes: [optionsPage] } } = data
  const { currentPage, limit, newsPageCount, frettirPageCount, skip } = pageContext


  const currentLangPosts = allWpPost.nodes.filter(item => item.language.locale === language.locale)

  return (
    <>
      <Seo title={title} lang={language.locale} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <NewsPageLayout
        language={language}
        newsData={currentLangPosts}
        currentPage={currentPage}
        limit={limit}
        newsPageCount={language.locale === 'is_IS' ? frettirPageCount : newsPageCount}
        skip={skip}
      />
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}

export const newsQuery = graphql`
  query newsById($id: String) {
    wpPage(id: {eq: $id}) {
      title
      translations {
        uri
      }
      language {
        locale
      }
      slug
    }
    allWpPost(sort: {fields: [date], order:DESC}) {
      nodes {
        id
        title
        date(formatString: "MMMM DD, YYYY")
        slug
        uri
        language {
          locale
          slug
        }
        featuredImage {
          node {
            file: localFile {
              newsImage: childImageSharp {
                fluid(maxWidth: 400) {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData 
      }
    }
  }
`

newsArchiveTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}